import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from './core/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private swUpdate: SwUpdate,
    public authService: AuthService) {
    swUpdate.available.subscribe(() => {
      if (confirm('New version available. Load new version?')) {
        window.location.reload();
      }
    });
  }

  logout = () => {
    this.authService.logout();
  };
}
