<section>
	<aside *ngIf="authService.userData && authService.userData.emailVerified && authService.userData as user">
		<header>
			<img [src]="user?.photoURL" alt="">
			<h2>{{ user?.displayName }}</h2>
		</header>
	
		<nav>
			<a href=""></a>
			<a href=""></a>
		</nav>
	
		<footer>
			<button class="logout invert" (click)="logout()">Logout</button>
		</footer>
	</aside>
	<div class="content">
		<router-outlet></router-outlet>
	</div>
</section>