import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/auth';
import { User } from 'src/app/shared/services/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userData: any;

  constructor(private authFire: AngularFireAuth,
              private router: Router,
              private fireStore: AngularFirestore) {
    this.authFire.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.removeItem('user');
      }
    });
  }

  googleLogin = (): void => {
    this.authByProvider(new firebase.auth.GoogleAuthProvider());
  };

  facebookLogin = (): void => {
    this.authByProvider(new firebase.auth.FacebookAuthProvider());
  };

  authByProvider = (provider: any): void => {
    firebase.auth().signInWithPopup(provider)
    .then((result) => {
      this.setUserData(result.user);
      this.router.navigate(['/home']);
    });
  };

  logout = (): void => {
    this.authFire.signOut()
    .then(() =>  {
      localStorage.removeItem('user');
      this.userData = null;
      this.router.navigate(['/login']);
    });
  };

  signIn = (email: string, password: string) =>
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then((result) => {
      if (result.user?.emailVerified) {
        this.setUserData(result.user);
        this.router.navigate(['/home']);
      } else {
        window.alert('E-mail não verificado');
      }
    }).catch((error) => {
      window.alert(error.message);
    });

  signUp = (email: string, password: string) =>
    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((result) => {
      // this.fireStore.collection('account').doc(result.user?.uid).set({
      //   field: 'Campo',
      //   anotherField: 'Oto campo'
      // });
      this.sendVerificationMail();
      this.setUserData(result.user);
    });

  sendVerificationMail = () =>
    this.authFire.currentUser
    .then(
      (response) => response?.sendEmailVerification()
      .then(
        () => {
          this.router.navigate(['/verify-email']);
        }
      )
    );

  setUserData = (user: any) => {
    const userRef: AngularFirestoreDocument<any> = this.fireStore.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };
    return userRef.set(userData, {
      merge: true
    });
  };

  forgotPassword = (email: string) => firebase.auth().sendPasswordResetEmail(email);

}
